import React from "react";
import { Helmet } from "react-helmet";
import { Container, Wrapper } from "../components/util";
import Layout from "../components/layout";
import Seo from "../components/seo";

const NotFoundPage = (props) => (
  <Layout>
    <Seo
      title="Oops... Page Not Found | 404"
      pathname={props.location.pathname}
    />
    <Helmet>
      <meta name="robots" content="noindex" />
    </Helmet>
    <Container>
      <Wrapper>
        <h1>404!</h1>
      </Wrapper>
    </Container>
  </Layout>
);

export default NotFoundPage;
